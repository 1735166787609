import React from 'react';
import { Button, Spin, Dropdown } from 'antd';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import { getTimeStr } from 'utils/calendar';
import StarReview from 'components/StarReview';
import { TReviewComment, TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import AjustText from 'components/AjustText';
import { getKlarityReviewReply } from 'api/survey';
import ReviewDisputeStore from 'store/ReviewDispute';
import { EDISPUTE_STATUS } from 'types/common';
import DisputeStatus from 'components/DisputeStatusTag';
import OriginDayjs from 'dayjs';

type Props = {
    showDispute?: boolean;
    review?: TReviewItem,
    onClickReply?: () => void,
    isShow?: boolean,
    hideRightPart?: boolean,
    hideBorder?: boolean,
};

const ReviewItem = (props: Props) => {
    const { review, onClickReply, isShow, hideRightPart, hideBorder, showDispute } = props;
    const [,setDisputeStore] = ReviewDisputeStore.useStore();
    const [showReply, setShowReply] = React.useState(false);
    const [replyComments, setReplyComments] = React.useState<TReviewComment[]>();
    const [loadingReply, setLoadingReply] = React.useState(false);
    const [textContentWidth, setTextContentWidth] = React.useState<number>(0);

    const canReply = !!(review?.isNeedReplied && review?.commentId);
    const hasReply = !!review?.isReplied;
    if (!review) {
        return null;
    }
    const verified = review.source !== 'PROVIDER_IMPORT';

    const fetchReply = async () => {
        if (review?.comment?.id) {
            setLoadingReply(true);
            const result = await getKlarityReviewReply(review?.id);
            if (!result?.error) {
                const replys: TReviewComment[] = result?.data?.data;
                setReplyComments(replys);
            }
            setLoadingReply(false);
        }
    };

    const handleShowDisputeDetail = () => {
        setDisputeStore('currentReview', review);
        setDisputeStore('showViewModal', true);
    };

    const handleMenuClick = (e) => {
        const { key } = e;

        if (key === '1') {
            setDisputeStore('currentReview', review);
            setDisputeStore('showEditModal', true);
            setDisputeStore('showViewModal', false);
        }
    };

    React.useEffect(() => {
        if (hasReply) {
            fetchReply();
        }
    }, [hasReply]);

    const displayComment: TReviewComment | undefined = replyComments ? replyComments[0] : undefined;

    const hasTag = !!review?.tags?.trim();

    const menuItems = [
        {
            key: '1',
            label: 'Dispute',
        },
    ];

    const buttonRender = () => {
        if (review?.disputeStatus === EDISPUTE_STATUS.NORMAL) {
            if (canReply) {
                if (!hasReply) {
                    return (
                        <Dropdown.Button
                            onClick={onClickReply}
                            className={`${canReply ? s.btn : s.btnDisabled}`}
                            disabled={!canReply}
                            menu={{ items: menuItems, onClick: handleMenuClick }}
                        >Reply
                        </Dropdown.Button>
                    );
                } else {
                    return (
                        <Dropdown.Button
                            onClick={onClickReply}
                            className={s.btnDisabled}
                            disabled
                            menu={{ items: menuItems, onClick: handleMenuClick }}
                        >Replied
                        </Dropdown.Button>
                    );
                }
            }
        } else if (canReply) {
            if (hasReply) {
                return (
                    <Button
                        onClick={onClickReply}
                        className={s.btnDisabled}
                        disabled
                    >Replied
                    </Button>
                );
            } else {
                return (
                    <Button
                        onClick={onClickReply}
                        className={`${canReply ? s.btn : s.btnDisabled}`}
                        disabled={!canReply}
                    >Reply
                    </Button>
                );
            }
        }
    };

    return (
        <div className={`${s.wrap} ${hideBorder ? s.hideBorder : ''}`} key={`${review.id}`}>
            <div className={s.left}>
                {!verified && <div className={s.name}>Anonymous</div>}
                {verified &&
                    <div className={s.name}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <path d="M15.8955 4.44316L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.96266C2.72656 15.0262 9.03931 17.499 9.30816 17.6016C9.37371 17.6263 9.44306 17.6396 9.51146 17.6396C9.58271 17.6396 9.65301 17.6263 9.72046 17.5997C9.98646 17.4952 16.2365 14.9739 16.2365 9.96266V4.96566C16.2375 4.73956 16.1035 4.53341 15.8955 4.44316Z" fill="#54EFA5" />
                            <path d="M16.2371 7.70121C15.8742 7.65656 15.5037 7.63281 15.1285 7.63281C10.4288 7.63281 6.57563 11.2552 6.20703 15.8598C7.72038 16.978 9.18148 17.5499 9.30783 17.5983C9.37338 17.623 9.44273 17.6363 9.51113 17.6363C9.58238 17.6363 9.65268 17.623 9.72013 17.5964C9.98613 17.4919 16.2362 14.9706 16.2362 9.95936V7.70121H16.2371Z" fill="#42E59B" />
                            <path d="M11.3668 2.47191L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.07726C6.85906 9.07726 10.3361 6.27761 11.3668 2.47191Z" fill="#5FFFA7" />
                            <path d="M8.92374 11.9836C8.84889 11.9837 8.77476 11.969 8.70563 11.9403C8.6365 11.9116 8.57373 11.8695 8.52094 11.8164L6.24094 9.53641C6.01864 9.31411 6.01864 8.95311 6.24094 8.72986C6.34782 8.62312 6.49269 8.56316 6.64374 8.56316C6.79479 8.56316 6.93967 8.62312 7.04654 8.72986L8.91804 10.6014L12.1043 7.32861C12.3238 7.10346 12.6848 7.09776 12.9099 7.31816C13.0181 7.42365 13.0799 7.56774 13.0819 7.7188C13.0838 7.86985 13.0258 8.01551 12.9204 8.12376L9.33034 11.8107C9.27767 11.8648 9.21476 11.9078 9.14528 11.9374C9.0758 11.9669 9.00114 11.9823 8.92564 11.9827C8.92659 11.9836 8.92564 11.9836 8.92374 11.9836Z" fill="white" />
                        </svg>
                        Verified patient
                    </div>
                }
                <div className={s.scoreInfo}>
                    <StarReview width={120} editable={false} value={review.rating} />
                    <div className={s.time}>{getTimeStr(dayjs(review?.submitAt), 'MMMM D, YYYY')}</div>
                    {
                        !review.isPublic && <span className={s.private}>Private</span>
                    }
                    {
                        review.isPublic && <span className={s.public}>Public</span>
                    }
                </div>
                {
                    hasTag &&
                    <div className={s.tagList}>
                        {
                            review?.tags
                        }
                    </div>
                }
                <div className={s.content}>
                    <AjustText
                        preLine
                        content={review?.comment?.content}
                        line={2}
                        isShow={isShow}
                    />
                </div>
                {
                    hasReply &&
                    <div className={s.replyWrap}>
                        <div className={s.header}>
                            <div className={s.label}>Response from you</div>
                            <div className={s.time}>{review?.repliedAt ? getTimeStr(dayjs(review?.repliedAt), 'MMMM D, YYYY') : ''}</div>
                            {!showReply &&
                                <div
                                    onClick={() => {
                                        setShowReply(true);
                                    }}
                                    className={s.showText}
                                >
                                    View response
                                </div>}
                        </div>
                        {
                            showReply &&
                            <Spin size="small" spinning={loadingReply}><div className={s.replyContent}>{displayComment?.content}</div></Spin>
                        }
                    </div>
                }
            </div>
            {
                !hideRightPart && (
                    <div className={s.right}>
                        {
                            review?.disputeStatus !== EDISPUTE_STATUS.NORMAL && (
                                <div className={s.disputeInfo}>
                                    <div className={s.top}>
                                        <div className={s.time}>{review?.disputeDecisionAt ? `Last updated: ${OriginDayjs(review?.disputeDecisionAt).format('MMMM DD, YYYY')}` : `Disputed on: ${OriginDayjs(review?.disputeSubmitAt).format('MMMM DD, YYYY')}`} </div>
                                    </div>
                                    <DisputeStatus status={review?.disputeStatus} />
                                    <div className={s.viewDetail} onClick={handleShowDisputeDetail}>View details</div>
                                </div>
                            )
                        }
                        {buttonRender()}
                    </div>
                )
            }
        </div>
    );
};

export default ReviewItem;
