import React from "react";

export const TITLE = 'Explore the benefits of Ava’s auto-reply';

export const CONFIG = [
    {
        icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                <path d="M6.58203 9.08203H6.58953M9.58203 9.08203H9.58953M12.582 9.08203H12.5895M16.332 9.08203C16.332 12.3957 13.31 15.082 9.58203 15.082C8.42758 15.082 7.34082 14.8244 6.39053 14.3703L2.83203 15.082L3.87827 12.2921C3.2157 11.3638 2.83203 10.2627 2.83203 9.08203C2.83203 5.76832 5.85411 3.08203 9.58203 3.08203C13.31 3.08203 16.332 5.76832 16.332 9.08203Z" stroke="#00816B" stroke-width="1.48649" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
        title: 'Rapid conversion',
        description: 'Ava responds to prospects within minutes after patients send you an initial inquiry, increasing your chances of conversion before they seek other providers.',
    },
    {
        icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M12.75 6H14.25C15.0784 6 15.75 6.67157 15.75 7.5V12C15.75 12.8284 15.0784 13.5 14.25 13.5H12.75V16.5L9.75 13.5H6.75C6.33579 13.5 5.96079 13.3321 5.68934 13.0607M5.68934 13.0607L8.25 10.5H11.25C12.0784 10.5 12.75 9.82843 12.75 9V4.5C12.75 3.67157 12.0784 3 11.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V9C2.25 9.82843 2.92157 10.5 3.75 10.5H5.25V13.5L5.68934 13.0607Z" stroke="#00816B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
        title: 'Fluent communication',
        description: 'Ava crafts well-written messages, ensuring every response meets your practice’s high standards.',
    },
    {
        icon:
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M6.87868 11.1213C8.05025 12.2929 9.94975 12.2929 11.1213 11.1213M11.25 7.5H11.2425M6.75 7.5H6.7425M2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9Z" stroke="#00816B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
        title: 'Reduced burnout',
        description: 'Ava handles all conversations with prospects and sends them your direct booking links, allowing you to focus on current patient care and reduce email management stress',
    },
];
