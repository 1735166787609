import { Button, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import HeaderImage from 'assets/ehr-home/pic-1.png';
import Tutorial from './components/Tutorial';
import Solutions from './components/Solutions';
import Benefits from './components/Benefits';
import Features from './components/Features';
import { UNIPROFILE } from 'constants/common';
import PaymentStatus from 'components/PaymentStatus';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';
import PayCard from './components/PayCard';
import AccountCard from './components/AccountCard';
import SeatCard from './components/SeatCard';
import { setHasViewKiwiEhrPage } from 'utils/localstore';

const EhrProviderHomePage = () => {
    const navigate = useNavigate();
    const [activeId, setActiveId] = useState('solutions');
    const [data, loadingHome] = useProviderHomeInfo();
    const [isScrolling, setIsScrolling] = useState(false);
    const [uniprofileEntryPath] = useGetUniprofileEntry();
    const firstChannelCompleted = !!data?.firstChannelCompleted;
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    const uniprofileApproved = !!data?.uniprofileApproved;
    const uniprofileSubmitted = !!data?.uniprofileSubmitted;
    const uniprofileApproveCompleted = !!data?.uniprofileApproveCompleted;
    const ehrSetUpCompleted = !!data?.ehrSetUpCompleted;

    const isShowTips = useMemo(() => {
        return (
            uniprofileSubmitted && !uniprofileApproveCompleted && !ehrSetUpCompleted
        );
    }, [uniprofileSubmitted, uniprofileApproveCompleted, ehrSetUpCompleted]);

    const isEhrSetupCompleted = useMemo(() => { return ehrSetUpCompleted; }, [ehrSetUpCompleted]);
    //const isEhrSetupCompleted = false;
    const guideItems: TabsProps['items'] = [
        {
            key: 'tutorial',
            label: <div>{isEhrSetupCompleted ? 'Tutorial' : ''}</div>,
            children: <div />,
        },
        {
            key: 'solutions',
            label: <div>Solutions</div>,
            children: <div />,
        },
        {
            key: 'benefits',
            label: <div>Benefits</div>,
            children: <div />,
        },
        {
            key: 'features',
            label: <div>All features</div>,
            children: <div />,
        },
    ];

    const handleChange = (activeKey: string) => {
        setIsScrolling(true);
        setActiveId(activeKey);
        const element = document.getElementById(activeKey as string);
        const bodyElement = document.getElementById('siteLayout');
        const ehrHomeTab = document.getElementById('ehrHomeTab')?.offsetHeight || 0;
        bodyElement?.scrollTo({
            top: (element as HTMLBaseElement).offsetTop - ehrHomeTab - 10,
            behavior: 'smooth',
        });
        setIsScrolling(false);
    };

    useEffect(() => {
        const els = document.querySelectorAll('.ant-tabs-tab');
        const activeClassName = 'ant-tabs-tab-active';
        if (isScrolling) {
            return;
        }
        els.forEach((el) => {
            const classList = Array.from(el.classList).filter(
                (i) => i !== activeClassName,
            );
            if (el.getAttribute('data-node-key') === `${activeId}`) {
                classList.push(activeClassName);
                el.className = classList.join(' ');
            } else {
                el.className = 'ant-tabs-tab';
            }
        });
    }, [activeId]);

    useEffect(() => {
        setHasViewKiwiEhrPage('true');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            {
                threshold: 0.2,
            },
        );
        const watchingItems = Array.from(
            document.getElementsByClassName('watchingContent'),
        );
        watchingItems.forEach((item) => observer.observe(item));
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isEhrSetupCompleted) {
            setActiveId('tutorial');
        } else {
            setActiveId('solutions');
        }
    }, [isEhrSetupCompleted]);

    const renderAddonsManagement = () => {
        return (
            <>
                <div className={s.headerEhr}>
                    <h1 className={s.headerEhrTitle}>Kiwi EHR</h1>
                </div>
                <PaymentStatus />
                <div className={s.cardList}>
                    <AccountCard />
                    <SeatCard />
                    <PayCard />
                </div>
            </>
        );
    };

    const renderGetStarted = () => {
        return (
            <div className={s.header}>
                <div className={s.headerLeft}>
                    <h1 className={s.headerTitle}>
                        Elevate your practice with
                        <br />
                        <span>Kiwi Health’s EHR</span>
                    </h1>
                    <p className={s.headerInfo}>
                        Kiwi EHR is essential for seeing patients on Klarity and is the
                        preferred option for modern, telehealth-oriented practices.
                        Begin your seamless EHR experience today.
                    </p>
                    {isShowTips && (
                        <div className={s.tips}>
                            <div className={s.tipIcon} />
                            <div className={s.tipContent}>
                                <div className={s.tipDesc}>
                                    You can start setting up your EHR once your UniProfile is
                                    approved, which typically takes 7 business days. To check
                                    your approval status or for any assistance, please contact
                                    onboarding@helloklarity.com.
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={s.headerBtnWrap}>
                        {uniprofileApproveCompleted && !ehrSetUpCompleted && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate(`/${PATH.EHR_ONBOARDING}`);
                                }}
                                className={s.startedButton}
                            >
                                Get started
                            </Button>
                        )}
                        {!uniprofileSubmitted && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate(`${uniprofileEntryPath}?type=${UNIPROFILE}`);
                                }}
                                className={s.startedButton}
                            >
                                Create Uniprofile
                            </Button>
                        )}
                    </div>
                </div>
                <div className={s.headerRight}>
                    <img
                        src={HeaderImage}
                        className={s.headerImage}
                        alt="Elevate your practice with Kiwi Health’s EHR"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={s.wrapper}>
            {isEhrSetupCompleted && renderAddonsManagement()}
            {!isEhrSetupCompleted && renderGetStarted()}
            <div className={s.contentWrap}>
                <div className={s.tabs} id="ehrHomeTab">
                    <Tabs
                        defaultActiveKey="1"
                        activeKey={activeId}
                        items={guideItems}
                        onChange={(activeKey) => handleChange(activeKey)}
                    />
                </div>
                <div className={`${s.content} watchingContent`} id="tutorial">
                    {isEhrSetupCompleted && <Tutorial />}
                </div>

                <div className={`${s.content} watchingContent`} id="solutions">
                    <Solutions />
                </div>
                <div className={`${s.content} watchingContent`} id="benefits">
                    <Benefits />
                </div>
                <div className={`${s.content} watchingContent`} id="features">
                    <Features />
                </div>
            </div>
        </div>
    );
};

export default EhrProviderHomePage;
