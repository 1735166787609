import React from 'react';
import s from './s.module.less';

type IProps = {
    link: string
};

const WatchVideo: React.FC<IProps> = ({ link }) => {
    return <a className={s.videoLink} href={link} target="_blank" rel="noreferrer">Watch tutorial</a>;
};

export default WatchVideo;
