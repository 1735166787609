import React from 'react';
import { Button, Tooltip } from 'antd';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

const InitStep: React.FC<{ className: string, flag: boolean }> = ({ className, flag }) => {
    const navigate = useNavigate();
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Kiwi EHR Add-ons are designed to enhance your practice management
                    capabilities. These optional features allow you to customize your
                    Kiwi EHR to perfectly suit your needs and workflow. Explore add-ons
                    for e-prescribing, e-Fax, EPCS, etc.
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Tooltip
                    color="#fff"
                    overlayInnerStyle={{ color: '#333', padding: 16 }}
                    title={
                        flag
                            ? ''
                            : 'Please complete the EHR setup to explore available add-ons.'
                    }
                >
                    <Button
                        type="primary"
                        disabled={!flag}
                        onClick={() => {
                            navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`);
                        }}
                    >
                        Explore add-ons
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

export default InitStep;
