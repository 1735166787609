// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--vUrVz {\n  padding: 43px 0 0 16px;\n}\n.s-module__wrap--vUrVz .s-module__text--oqE0p {\n  margin-bottom: 36px;\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: left;\n  color: var(--gray-500);\n}\n.s-module__wrap--vUrVz .s-module__text--oqE0p .s-module__link--inEGT {\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  color: var(--main-green);\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/ExploreKiwiHealth/s.module.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AAFA;EAIQ,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AACR;AAXA;EAaY,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;EACA,eAAA;AACZ","sourcesContent":[".wrap {\n    padding: 43px 0 0 16px;\n\n    .text {\n        margin-bottom: 36px;\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-align: left;\n        color: var(--gray-500);\n\n        .link {\n            font-family: Inter;\n            font-size: 14px;\n            font-weight: 500;\n            line-height: 20px;\n            text-align: left;\n            color: var(--main-green);\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--vUrVz",
	"text": "s-module__text--oqE0p",
	"link": "s-module__link--inEGT"
};
export default ___CSS_LOADER_EXPORT___;
