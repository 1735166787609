import React, { useCallback } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import HomeStore from 'store/Home';
import { EStepTypes } from 'types/common';
import useGetStepInfo from 'hooks/useGetStepInfo';

interface IProps {
    klarityPlanOnboardingDone?: boolean;
}

const ExploreKiwiHealth = ({
    klarityPlanOnboardingDone,
}: IProps) => {
    const [stepInfo, stepInfoLoading] = useGetStepInfo(EStepTypes.KLARITY_STARTER_SET_UP);
    const [getHomeStore] = HomeStore.useStore();
    const homeStore = getHomeStore('data');
    const practiceFrontCompleted = !!homeStore?.practiceFrontCompleted;
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (!stepInfo || !stepInfo.currentStep) {
            navigate(`/${PATH.KIWI_KNOWLEDEG}`);
        } else {
            navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
        }
    }, [navigate, stepInfo]);

    const handleGotoChannelsPage = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
    }, [navigate]);

    const handleGotoPFPage = useCallback(() => {
        if (!practiceFrontCompleted) {
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`);
        } else {
            navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}`);
        }
    }, [navigate, practiceFrontCompleted]);

    return (
        <div className={s.wrap}>
            {
                klarityPlanOnboardingDone ? (
                    <div className={s.text}>
                        <p>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</p>
                        <p>You can also browse <span className={s.link} onClick={handleGotoChannelsPage}>Channels</span> and customize your <span className={s.link} onClick={handleGotoPFPage}>Practice front</span> to expand your reach.</p>
                    </div>
                ) : (
                    <>
                        <div className={s.text}>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</div>
                        <Button type="primary" onClick={handleClick} loading={stepInfoLoading}>Explore Kiwi Health</Button>
                    </>
                )
            }
        </div>
    );
};

export default ExploreKiwiHealth;
