import React from 'react';
import { Button, Tooltip } from 'antd';
import s from './s.module.less';
import WatchVideo from '../WatchVideo';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

const InitStep: React.FC<{
    className: string;
    flag: boolean;
    isEhrSetUpCompleted: boolean;
}> = ({ className, flag, isEhrSetUpCompleted }) => {
    const navigate = useNavigate();
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Kiwi EHR helps you run your practice smoothly. It offers tools for
                    forms, telehealth, scheduling, billing, charting, and more.
                </p>
                <p>
                    To get started, follow our step-by-step onboarding guide for easy
                    setup and customization.
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Tooltip
                    color="#fff"
                    overlayInnerStyle={{ color: '#333', padding: 16 }}
                    title={
                        flag || isEhrSetUpCompleted
                            ? ''
                            : 'Please complete your UniProfile first to start setting up your EHR.'
                    }
                >
                    <Button
                        type="primary"
                        disabled={!flag && !isEhrSetUpCompleted}
                        onClick={() => {
                            navigate(`/${PATH.EHR_ONBOARDING}`);
                        }}
                    >
                        Get started
                    </Button>
                </Tooltip>

                <WatchVideo link="https://youtube.com/playlist?list=PLBr6KvIG41IQVZh6XY6FlQT9Z1crFXkB_&si=cHjeGUzVuhpVbG4O" />
            </div>
        </div>
    );
};

export default InitStep;
