import { Button, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import HeaderImage from './images/topbanner.png';
import Keyfeature from './components/Keyfeature';
import Benefits from './components/Benefits';
import Features from 'pages/dashboard/EhrProviderHomePage/components/Features';
import Partnerchannels from './components/Partnerchannels';
import { UNIPROFILE } from 'constants/common';
import PaymentStatus from 'components/PaymentStatus';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';
import PayCard from 'pages/dashboard/EhrProviderHomePage/components/PayCard';
import AccountCard from 'pages/dashboard/EhrProviderHomePage/components/AccountCard';
import SeatCard from 'pages/dashboard/EhrProviderHomePage/components/SeatCard';
import { setHasViewKiwiEhrPage } from 'utils/localstore';

const EhrProviderHomePage = () => {
    const navigate = useNavigate();
    const [activeId, setActiveId] = useState('solutions');
    const [data, loadingHome] = useProviderHomeInfo();
    const [isScrolling, setIsScrolling] = useState(false);
    const [uniprofileEntryPath] = useGetUniprofileEntry();
    const firstChannelCompleted = !!data?.firstChannelCompleted;
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    const uniprofileApproved = !!data?.uniprofileApproved;
    const uniprofileSubmitted = !!data?.uniprofileSubmitted;
    const uniprofileApproveCompleted = !!data?.uniprofileApproveCompleted;
    const ehrSetUpCompleted = !!data?.ehrSetUpCompleted;

    const isShowTips = useMemo(() => {
        return (
            uniprofileSubmitted && !uniprofileApproveCompleted && !ehrSetUpCompleted
        );
    }, [uniprofileSubmitted, uniprofileApproveCompleted, ehrSetUpCompleted]);

    const isEhrSetupCompleted = useMemo(() => { return ehrSetUpCompleted; }, [ehrSetUpCompleted]);

    const guideItems: TabsProps['items'] = [
        {
            key: 'benefits',
            label: <div>Benefits</div>,
            children: <div />,
        },
        {
            key: 'keyfeature',
            label: <div>Key feature</div>,
            children: <div />,
        },
        {
            key: 'partnerChannels',
            label: <div>Solutions</div>,
            children: <div />,
        },
    ];

    const handleChange = (activeKey: string) => {
        setIsScrolling(true);
        setActiveId(activeKey);
        const element = document.getElementById(activeKey as string);
        const bodyElement = window;
        const ehrHomeTab = document.getElementById('ehrHomeTab')?.offsetHeight || 0;

        bodyElement?.scrollTo({
            top: (element as HTMLBaseElement).offsetTop - ehrHomeTab - 10,
            behavior: 'smooth',
        });
        setIsScrolling(false);
    };

    useEffect(() => {
        const els = document.querySelectorAll('.ant-tabs-tab');
        const activeClassName = 'ant-tabs-tab-active';
        if (isScrolling) {
            return;
        }
        els.forEach((el) => {
            const classList = Array.from(el.classList).filter(
                (i) => i !== activeClassName,
            );
            if (el.getAttribute('data-node-key') === `${activeId}`) {
                classList.push(activeClassName);
                el.className = classList.join(' ');
            } else {
                el.className = 'ant-tabs-tab';
            }
        });
    }, [activeId, isScrolling]);

    useEffect(() => {
        setHasViewKiwiEhrPage('true');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            {
                threshold: 0.2,
            },
        );
        const watchingItems = Array.from(
            document.getElementsByClassName('watchingContent'),
        );
        watchingItems.forEach((item) => observer.observe(item));
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isEhrSetupCompleted) {
            setActiveId('tutorial');
        } else {
            setActiveId('solutions');
        }
    }, [isEhrSetupCompleted]);

    const renderGetStarted = () => {
        return (
            <div className={s.header}>
                <div className={s.headerLeft}>
                    <h1 className={s.headerTitle}>
                        Elevate your practice with
                        <br />
                        <span>Kiwi Health</span>
                    </h1>
                    <p className={s.headerInfo}>
                        Kiwi Health offers comprehensive solutions to grow and manage your healthcare practice with cutting-edge innovation and efficiency. As Klarity users, you can leverage these powerful tools to enhance your practice’s visibility, streamline operations, and significantly boost patient engagement.
                    </p>
                    <div className={s.headerBtnWrap}>
                        <Button
                            onClick={() => {
                                window.open('https://www.kiwihealth.com', '_blank');
                            }}
                            className={s.startedButton}
                        >
                            Explore Kiwi Health
                        </Button>
                    </div>
                </div>
                <div className={s.headerRight}>
                    <img
                        src={HeaderImage}
                        className={s.headerImage}
                        alt="Elevate your practice with Kiwi Health’s EHR"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={s.wrap}>
            {renderGetStarted()}
            <div className={s.contentWrap}>
                <div className={s.tabs} id="ehrHomeTab">
                    <Tabs
                        defaultActiveKey="1"
                        items={guideItems}
                        onChange={(activeKey) => handleChange(activeKey)}
                    />
                </div>
                <div className={`${s.content} watchingContent`} id="benefits">
                    <Benefits />
                </div>
                <div className={`${s.content} watchingContent`} id="keyfeature">
                    <Keyfeature />
                </div>
                <div className={`${s.content} watchingContent`} id="partnerChannels">
                    <Partnerchannels />
                </div>
            </div>
            <div className={s.footer}>
                <Button type="primary" onClick={() => navigate(`/${PATH.KLARITY_SUBSCRIPTION}`)}>Next</Button>
            </div>
        </div>
    );
};

export default EhrProviderHomePage;
