// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/home/play.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__videoLink--Ijs2B {\n  text-decoration: underline;\n  color: var(--main-green);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  display: flex;\n  align-items: center;\n  margin-left: 16px;\n}\n.s-module__videoLink--Ijs2B::before {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;\n  background-size: 100% auto;\n  width: 24px;\n  height: 24px;\n  content: '';\n  margin-right: 4px;\n}\n.s-module__videoLink--Ijs2B:hover {\n  opacity: 0.8;\n  color: var(--main-green);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/WatchVideo/s.module.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AAAE;EACE,iEAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AAEJ;AAAE;EACE,YAAA;EACA,wBAAA;AAEJ","sourcesContent":[".videoLink {\n  text-decoration: underline;\n  color: var(--main-green);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  display: flex;\n  align-items: center;\n  margin-left: 16px;\n  &::before {\n    background: url(assets/home/play.svg) 0 0 no-repeat;\n    background-size: 100% auto;\n    width: 24px;\n    height: 24px;\n    content: '';\n    margin-right: 4px;\n  }\n  &:hover {\n    opacity: .8;\n    color: var(--main-green);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoLink": "s-module__videoLink--Ijs2B"
};
export default ___CSS_LOADER_EXPORT___;
